[data-read-lines] {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    overflow: hidden;
    margin-bottom: 0;
    position: relative;

    &[aria-expanded="false"]:after {
      content: '';
      display: block;
      width: 100%;
      height: 45px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.2) 100%);
      pointer-events: none;
    }
  }
}