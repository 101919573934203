.esurprise-teaser {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5015.9 1337.2'%3E%3Cpolygon fill='%2362C0BB' points='0,1337.2 5015.9,1114.3 5015.9,0 0,0 '/%3E%3C/svg%3E");
  padding: 35px 30px 55px 30px;
  text-align: center;

  @include media-breakpoint-up(sm) {
    padding: 35px 70px 70px 70px;
  }

  > * {
    max-width: 550px;
  }

  > * + * {
    margin-top: 20px;
  }
}