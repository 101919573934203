.esurprise-breaker-container {
  margin-top: 53px;
  margin-bottom: 36px;

  @include media-breakpoint-up(sm) {
    margin-top: 60px;
    margin-bottom: 48px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.esurprise-breaker {
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 45px;

  @include media-breakpoint-down(sm) {
    padding-top: 70px;
    text-align: center;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(
                    180deg,
                    #514358 0%,
                    rgba(81, 67, 88, 0.2413340336) 100%
    );
  }

  &__category {
    position: absolute;
    top: -45px;
    right: 50px;
    z-index: 30;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1417.32 1417.32'%3E%3Cpath fill='%23fecc5e' d='M723.23,81.78c-634.72,0-634.72,634.72-634.72,1269.44H723.23c350.55,0,634.72-284.17,634.72-634.72,0-350.55-284.17-634.72-634.72-634.72Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    color: white;
    height: 120px;
    width: 120px;
    padding: 44px 23px;
    font-size: 25px;
    line-height: 22px;
    text-align: center;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      height: 100px;
      width: 100px;
      right: calc(50% - 50px);
      font-size: 19px;
      padding: 32px 23px;
    }
  }

  &--inverted {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  &--inverted .esurprise-breaker__category {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1417.3 1417.3' xml:space='preserve'%3E%3Cpath fill='%23FECC5E' d='M723.2,81.8C372.7,81.8,88.5,366,88.5,716.5s284.2,634.7,634.7,634.7h634.7 C1357.9,716.5,1357.9,81.8,723.2,81.8L723.2,81.8z'/%3E%3C/svg%3E%0A");
    @include media-breakpoint-up(md) {
      left: 50px;
      right: auto;
    }
  }

  &--inverted .esurprise-breaker__content {
    @include media-breakpoint-up(md) {
      padding-right: 0;
      padding-left: 170px;
    }
  }

  &__content {
    position: relative;
    z-index: 20;
    @include media-breakpoint-up(md) {
      padding-right: 170px;
    }

    > * + * {
      margin-top: 20px;
    }
  }

  &__subtitle {
    color: #ef476f;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 28px;
    position: relative;
  }

  &__title {
    color: white;
    margin-bottom: 0;
    font-size: 33px;
    font-size: clamp(22px, 4vw, 33px);
  }
}