.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 500px;

  &--home {
    height: 800px;
    min-height: 500px;
    max-height: 100vh;
  }

  &__items {
    display: flex;
    align-items: center;
    padding: 70px 0 50px;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;
  }
}