.esurprise-discount-badge-container {
  position: relative;
}

.esurprise-discount-badge {
  position: absolute;
  bottom: calc(-50px + 2vw);
  right: 0;
  z-index: 30;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1417.32 1417.32'%3E%3Cpath fill='%23EF476F' d='M723.23,81.78c-634.72,0-634.72,634.72-634.72,1269.44H723.23c350.55,0,634.72-284.17,634.72-634.72,0-350.55-284.17-634.72-634.72-634.72Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  color: white;
  height: 120px;
  width: 120px;
  padding: 44px 23px;
  font-size: 25px;
  line-height: 22px;
  text-align: center;
  font-weight: 700;

  @include media-breakpoint-down(sm) {
    height: 100px;
    width: 100px;
    left: 15px;
    bottom: 30px;
    font-size: 19px;
    padding: 32px 23px;
  }
}